.date-picker-button {
    border: 1px solid #cdcdcd;
    padding: 4px 12px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 500;
    color: #000000;
    text-align: left;
    width: 200px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 1px 1px rgb(208 208 208 / 25%);
    /* width: 100%; */
    /* width: 100%; */
    padding: 7px 14px;
    /* max-width: 290px; */
    font-size: 14px;
    border-radius: 0.375rem;
    border-color: rgba(209, 213, 219, 1);
    background-color: #ffffff;
}

.react-datepicker-popper {
    z-index: 10000;
}

.date-picker-button:hover {
    background-color: #f9fafb;
}

.date-picker-icon {
    height: 15px;
    margin-top: auto;
    margin-bottom: auto;
}

.date-picker-text-placeholder {
    margin-top: auto;
    margin-bottom: auto;
    color: #b6b6b6;
}

.date-picker-text {
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 400;
}


.date-picker-header {
    margin: 10px;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
}

.date-picker-header-title {
    font-size: 14px;
    font-family: Avenir Next;
    font-weight: 600;
    color: #717171;
}

.back-small-arrow {
    height: 15px;
    cursor: pointer;
    margin-bottom: auto;
    margin-top: auto;
}

.next-small-arrow {
    height: 15px;
    cursor: pointer;
    margin-bottom: auto;
    margin-top: auto;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
    cursor: pointer;
    color: #3c3c3c;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
    /* background-color: #EEEEEE; */
    border-radius: 6px;
    font-weight: 500;
    font-size: 13px;
    font-family: Avenir Next;
}




.react-datepicker__day-name,
.react-datepicker__time-name {
    color: #A9A9A9;
    display: inline-block;
    width: 1.7rem;
    /* line-height: 1.7rem; */
    text-align: center;
    margin: 0.166rem;
    font-family: 'Avenir Next';
    font-weight: 500;
}

.react-datepicker__day--selected {
    background-color: #177afa !important;
    color: #ffffff !important;
}

.react-datepicker__day:hover {
    cursor: pointer;
    background-color: #177afa;
    color: #ffffff;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    background-color: #018142;
    color: #ffffff;
}

.beautiful-cal {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px 1px #BDBDBD;
    border-radius: 10px;
    padding: 4px;
    display: flex;
    flex-direction: column;
}

.today-button {}

.react-datepicker__day--outside-month {
    color: #b8b8b8;
    pointer-events: none;
}

.hidden-picker {
    display: none;
}

.calendar-buttons {
    margin-right: 10px;
    margin-left: 10px;
}

.calendar-button {
    padding: 6px 8px;
    background-color: #eeeeee;
    border-radius: 6px;
    color: #1D1E20;
    margin-bottom: 8px;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
}

.react-datepicker__day--today {
    font-weight: 500;
    border: 2px solid #177afa;
    color: #177afa;
    background-color: #ffffff;
}

.calendar-button:hover {
    background-color: #177afa;
    color: #ffffff;
}

.react-datepicker-wrapper {
    height: 1px;
}

.react-datepicker__header {
    text-align: center;
    background-color: transparent;
    border-bottom: none;
    border-top-left-radius: 0.3rem;
    padding-top: 4px;
    position: relative;
}

.react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
    margin-top: 0px;
}

.date-picker-header {
    margin: 10px;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
}