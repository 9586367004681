.payment-chevron {
  height: 20px;
  color: #ababab;
}

.payment-modal .ant-modal-content {
  background-color: #f8f8f8;
  border-radius: 6px;
}

.modal-lg .ant-modal-content {
  background-color: #f8f8f8;
  border-radius: 6px;
}

.modal-back-icon {
  height: 20px;
  margin-right: 5px;
}

.thin-button-right-icon {
  height: 10px;
  margin-left: 8px;
}

.thin-button-left-icon {
  height: 10px;
  margin-right: 5px !important;
}

.add-payment-method-icon {
  width: 60px;
  padding: 15px;
  background-color: #e0e0e0;
  border-radius: 12px;
  text-align: center;
  /* align-items: center; */
  justify-content: center;
  margin: auto;
  color: #636363;
}

.lock-icon {
  height: 50px;
  margin-right: 15px;
  color: #ff9d00;
}