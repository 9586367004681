* {
  outline: none;
}

/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

*:focus,
input {
  outline: none;
}

@font-face {
  font-family: 'Avenir Next Demi';
  src: url('./fonts/Avenir-Next/AvenirNext-DemiBold.woff2') format('woff2'),
    url('./fonts/Avenir-Next/AvenirNext-DemiBold.woff') format('woff'),
    url('./fonts/Avenir-Next/AvenirNext-DemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/Avenir-Next/AvenirNext-Bold.woff2') format('woff2'),
    url('./fonts/Avenir-Next/AvenirNext-Bold.woff') format('woff'),
    url('./fonts/Avenir-Next/AvenirNext-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/Avenir-Next/AvenirNext-Medium.woff2') format('woff2'),
    url('./fonts/Avenir-Next/AvenirNext-Medium.woff') format('woff'),
    url('./fonts/Avenir-Next/AvenirNext-Medium.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/Avenir-Next/AvenirNext-UltraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/Avenir-Next/AvenirNext-Regular.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/Avenir-Next/AvenirNext-Medium.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/Avenir-Next/AvenirNext-DemiBold.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/Avenir-Next/AvenirNext-Bold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/Avenir-Next/AvenirNext-Heavy.ttf');
  font-weight: 700;
}

body {
  margin: 0;
  font-family: 'Avenir Next';
  /* font-family: 'Avenir Next', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  font-size: 14px;
  line-height: 19px;
  background: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

svg.dropdown-icon {
  margin-left: 4px;
}

h1 {
  font-size: 28px;
  line-height: 38px;
  color: #262626;
}

h2 {
  font-weight: 400;
  font-size: 23px;
  line-height: 31px;
  color: #262626;
}

.ant-tabs-nav {
  background: #ffffff;
  border: 1.02823px solid #dddddd;
  box-sizing: border-box;
  border-radius: 8.22581px;
  padding: 20px;
  margin-right: 30px;
  width: 100%;
  max-width: 240px;
  height: fit-content;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #575757;
  margin-bottom: 10px;
  padding: 10px;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab svg {
  margin-right: 15px;
}

.ant-tabs-tab svg path {
  fill: #585858;
}

.ant-tabs-ink-bar {
  display: none;
}

.ant-tabs-tab span {
  font-weight: 400;
}

.ant-tabs-tab.ant-tabs-tab-active svg path {
  fill: #1890ff;
}

.ant-tabs-tab svg path {
  fill: #585858;
}

.ant-tabs-left > .ant-tabs-content-holder {
  border: 0;
  margin-left: 20px;
}

.formwrapper {
  margin: 20px 0;
}

.ant-card {
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 2px 3px 4px rgb(238 238 238 / 42%);
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
}

.ant-card.ant-card-bordered {
  width: 100%;
  max-width: 700px;
}

.ant-switch {
  margin-right: 10px;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 7px 20px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  font-weight: 400;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  padding: 5px 20px;
  margin-left: 20px;
}

.btn-outline {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(186, 186, 186, 0.25);
  border-radius: 5px;
}

.btn:hover {
  box-shadow: 0px 0px 9px 6px #c9c9c9;
}

.ant-btn-group > button:first-child {
  display: none;
}

th {
  padding: 10px;
  font-weight: 400;
}

td {
  padding: 5px;
  font-weight: 400;
}

th {
  font-style: normal;
  font-size: 14px;
  line-height: 19px;

  color: #3d3d3d;
}

.ant-btn-icon-only {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 3px 4px rgb(241 241 241 / 25%);
  border-radius: 4px !important;
  height: 22px;
}

img.authoricon {
  max-width: 35px;
  /* border-radius: 50px; */
  margin-right: 10px;
}

.table-responive {
  overflow: auto;
}

.uploaded-img {
  padding: 3px;
  border: 1px solid #eee;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  box-shadow: none;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.mob-menu,
.back-to-page {
  display: none !important;
}

ul,
li {
  list-style: none;
}

.sub-heading {
  margin-top: 20px;
}

.m-0 {
  margin: 0px !important;
}

.text-center {
  text-align: center;
}

.pt-30 {
  padding-top: 30px;
}

@media screen and (max-width: 991px) {
  .ant-tabs-nav {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) {
  .ant-tabs-nav {
    max-width: 100%;
    padding: 10px;
  }

  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .ant-tabs-nav {
    margin-right: 0px;
  }

  .ant-tabs-left
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    padding-left: 0px;
  }

  .d-mob-none {
    display: none;
  }

  .d-mob-block {
    display: block;
  }

  .ant-tabs-mobile,
  .back-to-page {
    display: block !important;
  }

  .d-none-nav .ant-tabs-nav {
    display: none !important;
  }

  .ant-tabs-left > .ant-tabs-content-holder {
    margin-left: 0px;
  }

  .ant-tabs-content-left h2 {
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 20px;
  }

  .ant-tabs-nav {
    border-color: transparent;
  }

  .ant-tabs-tab.ant-tabs-tab-active svg path,
  .ant-tabs-tab svg path {
    fill: #262626;
  }

  .ant-tabs-tab.ant-tabs-tab svg {
    width: 16px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
    color: #262626;
  }

  /* .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab div {
          display: flex;
          align-items: center;
      } */
  .heading {
    display: flex;
    align-items: center;
  }

  .mb-mob-0 {
    margin-bottom: 0px;
  }

  .side-bar-none .ant-tabs-nav,
  .desk-nav {
    display: none !important;
  }

  .ant-tabs-tab .mob-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    width: 100%;
  }

  .mob-nav > svg {
    margin-right: 0px !important;
  }

  .mob-nav > svg > path {
    fill: #b6b6b6 !important;
  }

  .mob-nav span {
    position: relative;
    top: -4px;
  }

  .mob-menu {
    text-align: left;
    display: block !important;
  }

  .mob-menu p {
    margin-bottom: 0px;
    color: #858585;
    font-size: 13px;
    font-weight: 400 !important;
  }

  .back-to-page {
    border: 1px solid #dbdbdb;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(186, 186, 186, 0.25);
    border-radius: 5px;
    padding: 6px 14px;
    background: #fff;
    font-weight: 400;
    font-size: 15px;
    color: #2d2d2d;
    display: inline-block;
  }

  .back-to-page svg {
    margin-right: 9px;
  }

  .nav-hidden {
    display: none !important;
  }

  .header-mob {
    background: #fff;
    padding: 11px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #e8e8e8;
  }

  .ant-tabs-content-left .ant-card-body {
    padding: 15px;
  }

  .header-mob a {
    color: #000000;
  }

  .formwrapper {
    margin: 10px 0;
  }

  .btn {
    font-size: 14px;
  }
}

/* 768px */
.antd-pro-avatar-list-avatarList ul {
  padding: 0;
}

.common-modal .ant-modal-header {
  border: none;
  background: none;
  padding: 0;
  margin-bottom: 20px;
}

.common-modal .ant-modal-body {
  padding: 0px 0px 0px 0px;
}

.common-modal .ant-modal-title {
  font-size: 20px;
  font-weight: 400;
}

.common-modal .ant-modal-content {
  border-radius: 8px;
  background: #ffffff;
  padding: 24px;
}

.common-modal .ant-card {
  background: none;
  border: none;
  padding: 0;
  box-shadow: none;
  margin-bottom: 0;
}

.common-modal .ant-card-body {
  padding: 0;
}

.common-modal .ant-modal-close {
  right: 25px;
  top: 15px;
  color: #939393;
  font-weight: normal;
}

.modal-lg {
  width: 100% !important;
  max-width: 500px;
}

.active-menu {
  background: #f1f1f1;
}

.active-menu a > div {
  color: #0d6cff !important;
}

/* table design */

.table-design2 {
  padding: 0 !important;
  box-shadow: 0px 4px 4px rgba(208, 208, 208, 0.25);
  margin-top: 0 !important;
  border: 1px solid #f0f0f0 !important;
}

.table-design2 th,
.table-design2 td {
  border-right: 1px solid #e9e9e9;
}

.table-design2 th:last-child,
.table-design2 td:last-child {
  border-right: 0px solid #e9e9e9;
}

.table-design2 tr {
  border-bottom: 1px solid #e9e9e9;
}

.table-design2 tr:last-child {
  border-bottom: 0px solid #e9e9e9;
}

.btn-rounded {
  background: #0d6cff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 2px 3px 4px rgba(238, 238, 238, 0.42);
  border-radius: 8px;
}

.f-bold,
.f-bold > span {
  font-weight: 600;
}

.title-project {
  font-size: 18px !important;
  line-height: 27px !important;
}

.dropbtn {
  background-color: rgb(255, 255, 255);
  color: rgb(55, 65, 81);
  padding: 8px 16px;
  font-size: 14px;
  display: flex;
  cursor: pointer;
  border: 1px solid rgb(209, 213, 219);
  border-radius: 6px;
  min-width: 200px;
  justify-content: space-between;
}

.dropbtn.disabled {
  background-color: #f5f5f5;
  color: rgb(176 176 176);
  padding: 8px 16px;
  font-size: 14px;
  display: flex;
  border: 1px solid rgb(209, 213, 219);
  border-radius: 6px;
  min-width: 200px;
  justify-content: space-between;
  cursor: not-allowed;
  pointer-events: none;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: rgb(249, 250, 251);
}

.dropbtn.disabled:hover,
.dropbtn.disabled:focus {
  background-color: #f5f5f5;
  color: rgb(176 176 176);
  padding: 8px 16px;
  font-size: 14px;
  display: flex;
  border: 1px solid rgb(209, 213, 219);
  border-radius: 6px;
  min-width: 200px;
  justify-content: space-between;
  cursor: not-allowed;
  pointer-events: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown.disabled {
  cursor: not-allowed;
}

.dropdown-content {
  margin-top: 10px;
  display: block;
  position: absolute;
  background-color: #ffffff;
  border-radius: 6px;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  border-color: #e5e7eb;
}

.dropdown-options {
  overflow-y: scroll;
  max-height: 180;
}

.dropdown-options div {
  color: black;
  padding: 9px 15px;
  text-decoration: none;
  display: block;
  font-weight: 400;
}

.dropdown-options div:hover {
  background-color: #ddd;
}

.dropdown-search {
  padding: 9px 15px;
  text-decoration: none;
  display: block;
  font-weight: 400;
  border-bottom: 1px solid #e4e4e4;
}

.dropdown-search:hover {
  background-color: #ffffff !important;
}

.trash-icon {
  color: #848484;
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.trash-icon:hover {
  color: 0d6cff;
}

.modal-icon {
  width: 40px;
  margin-right: 15px;
  margin-top: 0px;
  border-radius: 50%;
  background-color: #ffedcf;
  padding: 10px;
  color: #fb9d00;
}

.settings-sidebar-icon {
  width: 24px;
  color: rgb(156, 163, 175);
}

.settings-sidebar-item:hover .settings-sidebar-icon {
  color: #0d6cff;
}

.selected .settings-sidebar-icon {
  color: #0d6cff;
}

.selected {
  background-color: #ffffff !important;
}

.search-icon {
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  color: #9ca3af;
  display: block;
  height: 1.25rem;
  outline: none;
  vertical-align: middle;
  width: 1.25rem;
}

.card-delete-button {
  width: 20px;
  cursor: pointer;
  color: #686868;
  margin-right: 10px;
}

.card-delete-button:hover {
  color: #0c6cff;
}

.avatar-placeholder-settings {
  width: 32px;
  background-color: #000000;
  color: #ffffff;
  border-radius: 50%;
  padding: 8px;
}

.ant-notification-notice {
  border-radius: 9px;
  background: #141414;
  color: #dfdfdf;
}

.ant-notification-notice-message {
  color: #ffffff;
}

.ant-notification-notice-close {
  color: #ffffff;
}

.ant-notification-notice-close:hover {
  color: #8d8d8d;
}

.page-back-icon {
  height: 30px;
  margin-right: 5px;
}

.react-resizable-handle {
  bottom: 0px;
  right: 0;
  background-color: #c1d9ff;
  border-radius: 50%;
  border: 1px solid #ffd5d5;
  width: 15px !important;
  height: 15px !important;
  cursor: 'se-resize';
}

.react-drag-handle {
  background-color: #000000;
  border-radius: 50%;
  border: 1px solid #ffd5d5;
  top: 0px;
  left: 0px;
  position: absolute;
  width: 20px;
  height: 20px;
}

.more-dots-vertical {
  height: 22px;
  color: #a7a7a7;
  cursor: pointer;
}

.filter-clear-icon {
  height: 20px;
  cursor: pointer;
  margin-left: 5px;
  color: #c2c2c2;
}

.filter-clear-icon:hover {
  color: #0d6cff;
}

.sort-button-icon {
  height: 18px;
  color: #53535e;
  margin-right: 5px;
}

/* for sorting */

.dropdown-option {
  color: #303030;
  padding: 8px 15px;
  text-decoration: none;
  border-radius: 6px;
  margin-bottom: 2px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: #f1f1f1;
}

.direction-button {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 8px 15px;
  border-radius: 6px;
  margin-bottom: 2px;
  cursor: pointer;
}

.direction-button:hover {
  background-color: #f1f1f1;
}

.direction-icon {
  height: 20px;
  color: #303030;
  margin-right: 10px;
}

.direction-text {
  padding: 0px !important;
}

.direction-section {
  padding: 5px 0px !important;
  border-top: 1px solid #ededed;
}

.item-selected {
  background-color: #e9effc;
}

.item-selected {
  color: #273eff;
}

.item-selected:hover {
  background-color: #e9effc !important;
}

.item-selected .direction-icon {
  color: #273eff;
}

#sort-dropdown {
  padding: 6px;
}

.section-chevron {
  height: 15px;
  margin-right: 5px;
}
