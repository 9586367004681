.avatar-container {
    height: 35px;
    border-radius: 50%;
    margin-right: 9px;
    margin-top: auto;
    margin-bottom: auto;
}

.avatar-initials-container {
    align-items: center;
    background-color: #6b7280;
    border-radius: 9999px;
    border-style: solid;
    border-width: 0;
    box-sizing: border-box;
    display: inline-flex;
    height: 2.5rem;
    justify-content: center;
    outline: none;
    width: 2.5rem;
}

.avatar-initials-text {
    border-style: solid;
    border-width: 0;
    box-sizing: border-box;
    color: #fff;
    font-weight: 400;
    line-height: 1;
    outline: none;
}